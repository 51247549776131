<template>
  <v-col v-if="create">
    <v-btn
      depressed
      @click="save"
      color="primary"
      width="100%"
      v-hotkey="saveKeymap"
    >
      <span>{{ actionText }}</span>
    </v-btn>
  </v-col>
</template>

<script lang="babel" type="text/babel">
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  beforeDestroy() {
    this.cancelPreventDefaultSave()
  },
  created() {
    this.preventDefaultSave()
  },
  computed: {
    actionText() {
      if(this.dataAction.save && this.dataAction.save.text) {
        return this.$t(this.dataAction.save.text)
      }
      return this.$t('action.save')
    },
    create() {
      if(this.loadFailure) return false
      if(!this.dataAction) return true
      if(this.dataAction.save === false) return false

      if(typeof this.dataAction.save == 'function') {
        return this.dataAction.save()
      }
      if(this.dataAction.save && typeof this.dataAction.save.create == 'function') {
        return this.dataAction.save.create()
      }
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
